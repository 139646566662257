import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore } from "../../apis/firebase";
import {
  doc,
  setDoc,
  addDoc,
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import TransferModal from "./transfer_modal";

export default function WalletContent({ uid }) {
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [gcashNumber, setGcashNumber] = useState("");
  const [amount, setAmount] = useState(0);
  const [showTransferModal, setShowTransferModal] = useState(false);

  useEffect(() => {
    const q = query(
      collection(firestore, "transactions"),
      where("uid", "==", uid), // Adjust the field and condition as needed
      orderBy('timestamp', 'desc')
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setTransactions(employeeData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(
      collection(firestore, "wallets"),
      where("uid", "==", uid), // Adjust the field and condition as needed
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.forEach((doc) => {
        setWallet({ id: doc.id, ...doc.data() });
      });
    });

    return () => unsubscribe();
  }, []);

  function formatFirestoreTimestamp(firestoreTimestamp) {
    if(firestoreTimestamp == null){
      return null
    }
    // Convert Firestore Timestamp to JavaScript Date object
    const date = firestoreTimestamp.toDate();
  
    // Format the date
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const handleWithdrawSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate input
    if (!gcashNumber || amount <= 0) {
      setError("Please enter a valid Gcash number and amount.");
      return;
    }

    try {
      const depositRequest = {
        'requester_uid': uid,
        'requester' : 'admins',
        'bank_account_number': gcashNumber,
        amount,
        status: "pending",
        timestamp: serverTimestamp(),
      };

      await addDoc(collection(firestore, "withdrawal_requests"), depositRequest);
      setShowModal(false);
      setGcashNumber("");
      setAmount(0);
    } catch (error) {
      setError("Failed to submit deposit request. Please try again.");
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Container>
      <Actions>
        {
          wallet && 
          <>
          <h2>Balance: Php {wallet.balance.toFixed(2)}</h2>
          <button onClick={() => {setShowModal(true)}}>Withdraw</button>
          <button onClick={() => {setShowTransferModal(true)}}>Transfer</button>

          </>
        }
      </Actions>
      <table>
        <tr>
          <th>Time</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{formatFirestoreTimestamp(transaction.timestamp) ?? "Processing..."}</td>
              <td>{transaction.message}</td>
              <td>Php {transaction.amount.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <RegisterModal onClick={() => setShowModal(false)}>
          <form onSubmit={handleWithdrawSubmit} onClick={(e) => e.stopPropagation()}>
            <h2>Request Withdrawal</h2>
            {error && <Error>{error}</Error>}
            <div className="field">
              <input
                type="text"
                placeholder="Gcash Number"
                value={gcashNumber}
                onChange={(e) => setGcashNumber(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                type="number"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div>
              <input type="submit" value="Add" id="register_submit" />
            </div>
          </form>
        </RegisterModal>
      )}
      {showTransferModal && (
        <TransferModal
          show={showTransferModal}
          onClose={() => setShowTransferModal(false)}
          uid={uid}
        />
      )}
    </Container>
  );
}
const Error = styled.div`
  padding: 1em;
  background-color: #ff000045;
`;

const RegisterModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000001c;
  form {
    width: 500px;
    background-color: #fcfcfc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding-bottom: 2em;

    div {
      width: 250px;
      box-sizing: border-box;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 0em 2em;
      border-radius: 10px;
      box-sizing: border-box;
      border: none;
    }

    #register_submit {
      background-color: #1f6ad4;
      color: white;
      cursor: pointer;
    }
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  gap: 3em;
  table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #0000001d;

    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    th {
      /* border: 1px solid black; */
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      text-align: left;
      background-color: #04aa6d;
      color: white;
    }
    td {
      /* border: 1px solid black;    */
      height: 2em;
      padding-left: 10px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .action {
      display: flex;
      gap: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: space-evenly;
  padding : 2em;
  border-radius: 20px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  width: max-content;

  .search {
    background-color: white;
    padding: 0.75em;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #00000020;

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }

    img {
      height: 24px;
    }
  }

  button {
    border: none;
    border-radius: 15px;
    background-color: #1f6ad4;
    color: white;
    padding: 0em 1em;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
    cursor: pointer;
  }
`;
