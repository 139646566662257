import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore, secondaryAuth } from "../../apis/firebase";
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  query,
  where,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "@firebase/auth";

export default function AgentContent({ uid }) {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const q = query(
      collection(firestore, "agents"),
      where("admin", "==", uid) // Adjust the field and condition as needed
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setAdmins(employeeData);
    });

    return () => unsubscribe();
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    // console.log(firstName, lastName, email)
    // return;

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        secondaryAuth,
        email,
        password
      );

      const agent_uid = userCredential.user.uid;
      await setDoc(doc(firestore, "agents", agent_uid), {
        firstName,
        lastName,
        email,
        admin: uid,
      });

      setShowModal(false);
      setEmail("");
      setPassword("");
      setFirstName("");
      setLastName("");
    } catch (error) {
      setError(error.message); // Set error message if registration fails
      console.error(error);
    }
  };

  return (
    <Container>
      <Actions>
        <div className="search">
          <img src={searchIcon} alt="" srcset="" />
          <input type="text" placeholder="Search for people" />
        </div>
        <button onClick={() => setShowModal(true)}>Add Agent</button>
      </Actions>
      <table>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          {/* <th>Action</th> */}
        </tr>
        <tbody>
          {admins.map((employee) => (
            <tr key={employee.id}>
              <td>{employee.email ?? "Processing"}</td>
              <td>{employee.firstName ?? "Processing"}</td>
              <td>{employee.lastName ?? "Processing"}</td>
              {/* <td className="action"> */}
                {/* <button>View</button> */}
                {/* <button>Delete</button> */}
              {/* </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <RegisterModal onClick={() => setShowModal(false)}>
          <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
            <h2>Register Agent</h2>
            {error && <Error>{error}</Error>}
            <div className="field">
              <input
                type="text"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <input type="submit" value="Add" id="register_submit" />
            </div>
          </form>
        </RegisterModal>
      )}
    </Container>
  );
}
const Error = styled.div`
  padding: 1em;
  background-color: #ff000045;
`;

const RegisterModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000001c;
  form {
    width: 500px;
    background-color: #fcfcfc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding-bottom: 2em;

    div {
      width: 250px;
      box-sizing: border-box;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 0em 2em;
      border-radius: 10px;
      box-sizing: border-box;
      border: none;
    }

    #register_submit {
      background-color: #1f6ad4;
      color: white;
      cursor: pointer;
    }
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  gap: 3em;
  table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #0000001d;

    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    th {
      /* border: 1px solid black; */
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      text-align: left;
      background-color: #04aa6d;
      color: white;
    }
    td {
      /* border: 1px solid black;    */
      height: 2em;
      padding-left: 10px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .action {
      display: flex;
      gap: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2em;

  .search {
    background-color: white;
    padding: 0.75em;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #00000020;

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }

    img {
      height: 24px;
    }
  }

  button {
    border: none;
    border-radius: 15px;
    background-color: #1f6ad4;
    color: white;
    padding: 0em 1em;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
  }
`;
