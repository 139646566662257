import React, { useState } from "react";
import styled from "styled-components";
import { auth, firestore } from "../../apis/firebase";
import {
  collection,
  query,
  where,
  limit,
  onSnapshot,
  doc,
  updateDoc,
  addDoc,
  FieldValue,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "@firebase/auth";

const TransferModal = ({ show, onClose, uid }) => {
  const [receiverEmail, setReceiverEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [registering, setRegistering] = useState(false);

  const handleTransfer = async () => {
    if (!receiverEmail || !amount || !password) {
      alert("Please fill in all fields.");
      return;
    }

    const otherEmailAddress = receiverEmail;
    const transferAmount = parseFloat(amount);

    if (transferAmount <= 0) {
      alert("Amount must not be zero or below");
      return;
    }

    try {
      setRegistering(true);
      // Re-authenticate user
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Get the other user's UID by email
      const otherUserQuery = await getUserByEmail(otherEmailAddress);
      if (!otherUserQuery) {
        alert(`There was no user with the email ${otherEmailAddress}`);
        setRegistering(false);
        return;
      }

      const other_uid = otherUserQuery.docs[0].id;

      if (uid === other_uid) {
        alert("You cannot transfer to yourself");
        setRegistering(false);
        return;
      }

      // Fetch wallets
      const walletQuery = await getWalletByUid(uid);
      const otherWalletQuery = await getWalletByUid(other_uid);

      if (!walletQuery || !otherWalletQuery) {
        alert("Wallet not found.");
        setRegistering(false);
        return;
      }

      const walletDoc = walletQuery.docs[0];
      const otherWalletDoc = otherWalletQuery.docs[0];
      const currentBalance = walletDoc.data().balance;
      const otherBalance = otherWalletDoc.data().balance;

      if (currentBalance < transferAmount) {
        alert("Insufficient funds.");
        setRegistering(false);
        return;
      }

      // Update wallets
      await updateDoc(doc(firestore, "wallets", walletDoc.id), {
        balance: currentBalance - transferAmount,
      });

      await updateDoc(doc(firestore, "wallets", otherWalletDoc.id), {
        balance: otherBalance + transferAmount,
      });

      // Add transactions
      const transactionsColRef = collection(firestore, "transactions");

      await addDoc(transactionsColRef, {
        uid,
        message: "Transferred to other wallet",
        amount: -transferAmount,
        timestamp: serverTimestamp(),
      });

      await addDoc(transactionsColRef, {
        uid: other_uid,
        message: "Received from wallet transfer",
        amount: transferAmount,
        timestamp: serverTimestamp(),
      });
      setRegistering(false);
      onClose();
      alert("Transfer request submitted successfully");
    } catch (e) {
      console.error(e);
      alert(`Error: ${e.message}`);
      setRegistering(false);
    }
  };

  const getUserByEmail = async (email) => {
    const collections = ["users", "admins", "agents"];
    for (const collectionName of collections) {
      const q = query(
        collection(firestore, collectionName),
        where("email", "==", email),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot;
      }
    }
    return null;
  };

  const getWalletByUid = async (uid) => {
    const q = query(
      collection(firestore, "wallets"),
      where("uid", "==", uid),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty ? null : querySnapshot;
  };

  if (!show) {
    return null;
  }

  return (
    <TransferModalContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleTransfer();
        }}
      >
        <h2>Make a Transfer</h2>
        <div>
          <input
            type="email"
            placeholder="Email Address"
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="actions">
          <button type="submit" disabled={registering}>
            {registering ? "Processing..." : "Send Request"}
          </button>
          <button onClick={onClose}  disabled={registering} className="close">
            Close
          </button>
        </div>
      </form>
    </TransferModalContainer>
  );
};

const TransferModalContainer = styled.div`
  // Add styling similar to the provided styled-components
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 400px;
    background: white;
    padding: 2em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    input {
      padding: 0.5em;
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 100%;
      box-sizing: border-box;
    }

    button {
      background-color: #1f6ad4;
      color: white;
      border: none;
      padding: 0.75em;
      border-radius: 5px;
      cursor: pointer;

      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export default TransferModal;
