import React from "react";
import { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "@firebase/auth";
import { auth } from "../apis/firebase";
import styled from "styled-components";
import admin_logo from "../images/admin_logo.png";
import employee from "../images/employees.png";
import logout from "../images/shutdown.png";
import salary from "../images/salary.png";
import wallet from "../images/wallet.png";

export default function Navigation({ activeTab, onChangeTab, fullName }) {
  const handleChangeTab = (tabName) => {
    onChangeTab(tabName);
  };

  const handleLogout = async () => {
    await signOut(auth);
    window.location.reload();
  };

  return (
    <Container>
      <Logo>
        {/* <img src={logo} alt="" /> */}
        <h1>Live Betting</h1>
      </Logo>
      <UserContainer>
        <img src={admin_logo} alt="" srcset="" />
        <div>
          <p>{fullName}</p>
          <img
            className="logout"
            src={logout}
            alt=""
            srcset=""
            onClick={handleLogout}
          />
        </div>
      </UserContainer>
      <Tabs>
        <Tab
          imageSrc={employee}
          textContent="Agents"
          isActive={activeTab == "agents" ? true : false}
          onClick={() => handleChangeTab("agents")}
        />

        <Tab
          imageSrc={salary}
          textContent="Transactions"
          isActive={activeTab == "transactions" ? true : false}
          onClick={() => handleChangeTab("transactions")}
        />

        <Tab
          imageSrc={wallet}
          textContent="Wallet"
          isActive={activeTab == "wallet" ? true : false}
          onClick={() => handleChangeTab("wallet")}
        />
      </Tabs>
    </Container>
  );
}

const Tabs = styled.div`
  .active {
    background-color: #ffffff21;
  }
`;

export function Tab({ imageSrc, textContent, isActive, onClick }) {
  return (
    <TabContainer className={isActive ? "active" : ""} onClick={onClick}>
      <img src={imageSrc} alt="Description of your image" />
      <p>{textContent}</p>
    </TabContainer>
  );
}

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 2em;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  img {
    height: 40px;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: 225px;
  box-sizing: border-box;
  display: flex;
  background-color: #232336;
  color: white;
  flex-direction: column;
`;

const UserContainer = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  img {
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
    box-sizing: border-box;
  }
  .logout {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
`;

const Logo = styled.div`
  background-color: #ffffff14;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 1em;
  img {
    height: 75px;
  }
`;
