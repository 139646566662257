import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "@firebase/auth";
import { auth, firestore } from "../apis/firebase";
import { doc, getDoc } from "firebase/firestore";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Function to handle changes in the email input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to handle changes in the password input
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const adminDoc = await getDoc(doc(firestore, "admins", result.user.uid));
      if (!adminDoc.exists()) {
        alert("You are not authorized as an admin.");
        window.location.reload();
        return;
      }
      const adminData = adminDoc.data();
      if (adminData.deleted) {
        setError("Your account has been deleted");
        await result.user.delete();
        return;
      }
      window.location = "/";
    } catch (error) {
      console.log(error.code);
      const errorMessages = {
        "auth/invalid-email": "Invalid email address.",
        "auth/user-disabled": "User account is disabled.",
        "auth/user-not-found": "No user found with this email.",
        "auth/invalid-credential": "Incorrect email or password."
      };
      setError(errorMessages[error.code] || "Login failed. Please try again.");
      console.log(error);
    }
  };

  const handleForgotPassword = () => {
    const email = prompt("Enter your registered email address:");
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          alert("A password reset link has been sent to your email address.");
        })
        .catch((error) => {
          console.error("Error sending password reset email:", error);
          alert("There was an error sending the password reset email. Please try again.");
        });
    }
  }

  return (
    <Container>
      <Modal>
        <h2>Login as Admin</h2>
        {error && <Error>{`${error}`}</Error>}
        <Field>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Field>
        <Field>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Field>
        <Form>
          <button onClick={handleLogin}>Login</button>
        </Form>
        <button className="forgot" onClick={handleForgotPassword}>Forgot Password</button>
      </Modal>
    </Container>
  );
}

const Error = styled.div`
  padding: 1em;
  background-color: #ff0000b5;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  z-index: 0;
  position: relative;
`;

const Modal = styled.div`
  width: 400px;
  background-color: #f5f5f5d5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  padding-bottom: 3em;
  transform: scale(1.2);
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  position: relative;
  z-index: 100;

  .forgot{
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: none;
    cursor: pointer;
    color: #4974ffd4;
  }
`;

const Field = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 600;
  & > input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0.5em;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    box-sizing: border-box;
  }
`;

const Form = styled.div`
width: 60%;
  & > button {
    width: 100%;
    padding: 0.75em;
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    background: #5433ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #a5fecb,
      #20bdff,
      #5433ff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #a5fecb,
      #20bdff,
      #5433ff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  & > button:hover {
    background-color: #d8d8d8;
    cursor: pointer;
  }
  & > button:active {
    background-color: #c9c9c9;
    cursor: pointer;
  }
`;