import React, { act } from "react";
import { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "@firebase/auth";
import { auth, firestore } from "../apis/firebase";
import { doc, getDoc, collection, onSnapshot } from "firebase/firestore";
import Navigation from "./navigation";
import styled from "styled-components";
import Heading from "./heading";
import AgentContent from "./contents/agents_content";
import TransactionsContent from "./contents/transactions_content";
import WalletContent from "./contents/wallet_content";

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("agents");
  const [docSnap, setDocSnap] = useState(null);
  const [fullName, setFullName] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setUser(null);
        window.location = "/login";
        return;
      }
      setUser(user);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (!user) return;

    const docRef = doc(collection(firestore, "admins"), user.uid);

    const fetchDoc = async () => {
      const unsubscribeDoc = onSnapshot(docRef, async (docSnap) => {
        if (!docSnap.exists()) {
          await signOut(auth);
          window.location.reload();
          return;
        }

        const data = docSnap.data();
        if (data.deleted) {
          alert("Your account has been deleted")
          try {
            await user.delete();
          } catch (error) {
            await signOut(auth);
          } finally {
            window.location = "/login";
          }
          return;
        }

        setFullName(`${data.firstName} ${data.lastName}`);
        setDocSnap(docSnap);
      });

      return () => unsubscribeDoc();
    };

    fetchDoc();
  }, [user]);

  return (
    <Container>
      <Navigation activeTab={activeTab} onChangeTab={setActiveTab} fullName={fullName}/>
      <Content>
        <Heading activeTab={activeTab} />
        <div id="content">
          {user && (
            <>
              {activeTab == "agents" && <AgentContent uid={user.uid} />}
              {activeTab == "transactions" && <TransactionsContent uid={user.uid} />}
              {activeTab == "wallet" && <WalletContent uid={user.uid} />}
              {activeTab == "salary" && <p>Manage Salaries</p>}
              {activeTab == "department" && <p>Manage Departments</p>}
              {activeTab == "training" && <p>Manage Trainings</p>}
              {activeTab == "report" && <p>View Reports</p>}
              {activeTab == "designation" && <p>Assign Designations</p>}
            </>
          )}
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
`;

const Content = styled.div`
  flex-grow: 1;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  #content {
    width: 100%;
    flex-grow: 1;
  }
`;
