// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgQtYqJ6YE8vCVlGaCoFkov4fgH26wCq4",
  authDomain: "archie-c8688.firebaseapp.com",
  projectId: "archie-c8688",
  storageBucket: "archie-c8688.appspot.com",
  messagingSenderId: "204093880090",
  appId: "1:204093880090:web:b7b388d7b2659beca4dea3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const secondaryApp = initializeApp(firebaseConfig, 'secondary');
const secondaryAuth = getAuth(secondaryApp);

export { auth, firestore, secondaryAuth }
